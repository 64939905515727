import * as React from "react"

// ______________________________________________________
//

// ______________________________________________________
//
const Component: React.FC<{ url: string }> = ({ url }) => (
  <div>
    <a
      href={`https://b.hatena.ne.jp/entry/s/${url}`}
      className="hatena-bookmark-button"
      data-hatena-bookmark-layout="touch"
      data-hatena-bookmark-width="60"
      data-hatena-bookmark-height="60"
      title="このエントリーをはてなブックマークに追加"
    >
      <img
        src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
        alt="このエントリーをはてなブックマークに追加"
      />
    </a>
    <script
      type="text/javascript"
      src="https://b.st-hatena.com/js/bookmark_button.js"
      charSet="utf-8"
      async={true}
    ></script>
  </div>
)
// ______________________________________________________
//

// ______________________________________________________
//
export default Component
