import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Article, WithContext, Organization } from "schema-dts"
import { BlogTemplateQuery } from "../../types/graphql-types"

import Header from "../components/partials/layout/Header"
import Social from "../components/partials/post/Social"

import "../styles/blogTemplate.scss"
import Helmet from "react-helmet"
import { Footer } from "../components/partials/layout/Footer"
// ______________________________________________________
//
type Props = {
  data: BlogTemplateQuery
}
// ______________________________________________________
//
const Template: React.FC<Props> = ({
  data // this prop will be injected by the GraphQL query below.
}) => {
  const { mdx } = data // data.mdx holds your post data
  const { frontmatter, body, parent } = mdx as any
  const articlePublisher = ({
    "@type": "Organization",
    name: "ElevenBack LLC.",
    logo: {
      "@type": "ImageObject",
      url: "https://blog.candy.ac/static/images/icon.png",
      width: 60,
      height: 60
    }
  } as any) as Organization
  const articleInfo: WithContext<Article> = {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://blog.candy.ac"
    },
    headline: `${frontmatter.title}`,
    description: `${frontmatter.title}`,
    image: "https://blog.candy.ac/static/images/opengraph.png",
    author: {
      "@type": "Organization",
      name: "ElevenBack LLC."
    },
    publisher: articlePublisher,
    datePublished: "2019-12-26",
    dateModified: `${parent.modifiedTime}`
  }
  return (
    <div id="wrapper">
      <Helmet
        title={`${frontmatter.title} | Candy Blog`}
        meta={[
          { name: "description", content: `${frontmatter.title} | Candy Blog` },
          {
            name: "description",
            content: `${frontmatter.title} | Candy Blog`
          },
          {
            property: "og:site_name",
            content: `${frontmatter.title} | Candy Blog`
          },
          { property: "og:type", content: "website" },
          { property: "og:url", content: "https://blog.candy.ac/" },
          {
            property: "og:title",
            content: `${frontmatter.title} | Candy Blog`
          },
          { name: "twitter:card", content: "summary_large_image" },
          {
            property: "og:description",
            content: `${frontmatter.title} | Candy Blog`
          },
          {
            property: "og:image",
            content: `https://blog.candy.ac/static/images/opengraph.png`
          }
        ]}
        link={[
          { rel: "icon", type: "image/png", href: "/static/images/icon.png" }
        ]}
      >
        <script type="application/ld+json">
          {JSON.stringify(articleInfo)}
        </script>
      </Helmet>
      <Header></Header>
      <div id="content">
        <div className="BlogTemplateWrap">
          <div className="BlogTemplateWrapContent">
            <div className="BlogTemplateDate">
              {parent.modifiedTime.replace(/-/g, "/")}
            </div>
            <div className="BlogTemplateTitle">{frontmatter.title}</div>
            <MDXRenderer>{body}</MDXRenderer>
            <Social path={frontmatter.path} data={data}></Social>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export const blogTemplateQuery = graphql`
  query blogTemplate($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        path
        title
      }
      parent {
        ... on File {
          modifiedTime(formatString: "YYYY-MM-DD")
        }
      }
    }
  }
`
export default Template
