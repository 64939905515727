import * as React from "react"
import { Link, graphql } from "gatsby"
import { SocialQuery } from "../../../../types/graphql-types"

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  PocketShareButton,
  PocketIcon
} from "react-share"
import HatenaShareButton from "./HatenaShareButton"

import "./Social.scss"
// ______________________________________________________
//
type Props = {
  data: SocialQuery
  path: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = ({ data, path }) => {
  const { mdx } = data // data.mdx holds your post data
  const { frontmatter, body } = mdx as any
  const domain = "blog.candy.ac"
  const iconSize = 40
  return (
    <div className="SocialWrapShareButton">
      <div className="SocialClipShareButton">
        <TwitterShareButton
          url={`https://${domain}${path}`}
          title={`${frontmatter.title} | Candy Blog`}
        >
          <TwitterIcon size={iconSize} />
        </TwitterShareButton>
      </div>
      <div className="SocialClipShareButton">
        <FacebookShareButton
          url={`https://${domain}${path}`}
          quote={frontmatter.excerpt}
        >
          <FacebookIcon size={iconSize} />
        </FacebookShareButton>
      </div>
      <div className="SocialClipHatenaShareButton">
        <HatenaShareButton url={`${domain}${path}`} />
      </div>
      <div className="SocialClipShareButton">
        <PocketShareButton
          url={`https://${domain}${path}`}
          title={`${frontmatter.title} | Candy Blog`}
        >
          <PocketIcon size={iconSize} />
        </PocketShareButton>
      </div>
    </div>
  )
}
// ______________________________________________________
//
export const socialQuery = graphql`
  query social($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        path
        title
      }
    }
  }
`
// ______________________________________________________
//
export default Component
